<template>
    <div>
        <vue-title title="Водители | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Водители</h1>
            </div>
            <div class="row">
                <div class="col-12 text-right pb-3">
                    <DxButton
                            text="Добавить"
                            type="success"
                            styling-mode="contained"
                            icon="add"
                            @click="addNewDriver"
                    />
                </div>
                <div class="col-12">
                    <DxDataGrid
                            id="gridContainerDrivers"
                            :ref="dataGridRefKey"
                            :data-source="storeDrivers"
                            :columns="columnsDrivers"
                            :show-borders="true"
                            :row-alternation-enabled="true"
                            :show-row-lines="true"
                            :customize-columns="customizeColumnsDrivers"
                            :paging="{pageSize: pageSize, enabled: true}"
                            :pager="pager"
                            :remote-operations="true"
                            :filter-row="{visible: true}"
                            @row-click="onRowClick"
                    >
                        <DxSelection mode="single"/>
                        <DxHeaderFilter
                                :visible="true"
                        />
                        <DxMasterDetail
                                :enabled="true"
                                template="masterDetailTemplate"
                        />
                        <template #masterDetailTemplate="{ data }">
                            <Driver
                                    v-if="selectedRowKey == data.key"
                                    :driverId="data.key"
                                    :agent="Agent"
                                    :getDrivers="getDrivers"
                            />
                        </template>
                        <template #sex-cell-template="{ data }">
                            <div>
                                {{ getSexName(data.data.sex) }}
                            </div>
                        </template>
                        <template #action-cell-template="{ data }">
                            <div>
                                <DxButton
                                        text="Изменить"
                                        type="default"
                                        styling-mode="text"
                                        @click="editDriver(data.data.driverId)"
                                />
                                <DxButton
                                        text="Удалить"
                                        type="danger"
                                        styling-mode="text"
                                        @click="delDriver(data.data.driverId)"
                                />
                            </div>
                        </template>
                    </DxDataGrid>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import {httpClient} from '../../shared/services';
    import {Header, Footer} from '../../shared/components';
    import { DxDataGrid, DxMasterDetail, DxSelection, DxHeaderFilter } from 'devextreme-vue/data-grid';
    import CustomStore from 'devextreme/data/custom_store';
    import DxButton from 'devextreme-vue/button';
    import moment from 'moment';

    import Driver from './detail/Driver';

    const dataGridRefKey = "drivers-data-grid";

    export default {
        name: 'CarrierDrivers',
        components: {
            Header,
            Footer,
            DxDataGrid,
            DxMasterDetail,
            DxSelection,
            Driver,
            DxHeaderFilter,
            DxButton
        },
        data() {
            return {
                headerRoutes,
                storeDrivers: null,
                dataGridRefKey,
                selectedRowKey: "",
                pager: {
                    allowedPageSizes: [5, 10, 15, 30],
                    showInfo: true,
                    infoText: 'Страница {0} из {1} (Всего {2})',
                    showNavigationButtons: true,
                    showPageSizeSelector: true,
                    visible: true
                },
                pageSize: 10,
                columnsDrivers: [
                    {
                        caption: 'Фамилия',
                        dataField: 'lastName',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Имя',
                        dataField: 'firstName',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Отчество',
                        dataField: 'middleName',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'ИИН',
                        dataField: 'iin',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Пол',
                        dataField: 'sex',
                        allowSorting: true,
                        allowFiltering: false,
                        allowHeaderFiltering: true,
                        width: 140,
                        cellTemplate: "sex-cell-template",
                        headerFilter: {
                            dataSource: [{
                                text: 'Не указан',
                                value: 'Unknown'
                            }, {
                                text: 'Мужской',
                                value: 'Male'
                            }, {
                                text: 'Женский',
                                value: 'Famale'
                            }]
                        }
                    },
                    {
                        caption: 'Дата рождения',
                        dataField: 'birthday',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: true,
                        allowResizing: false,
                        allowFiltering: true,
                        allowHeaderFiltering: false,
                        width: 140
                    },
                    {
                        caption: 'Действие',
                        dataField: 'driverId',
                        allowSorting: false,
                        allowFiltering: false,
                        allowHeaderFiltering: false,
                        cellTemplate: "action-cell-template",
                        rowType: 'action'
                    }
                ]
            }
        },
        created() {
            window.addEventListener("resize", this.resizeEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeEventHandler);
        },
        mounted() {
            this.getDrivers();
        },
        methods: {
            getDrivers() {
                this.storeDrivers = new CustomStore({
                    key: 'driverId',
                    byKey: async function (key) {
                        if(key) {
                            console.log(key);
                        }
                    },
                    load: (loadOptions) => {

                        let tokenId = this.Agent.tokenId;
                        let sortStr = '';
                        let filterStr = '';
                        let takeStr = '';
                        let skeepStr = '';

                        if(loadOptions.take) {
                            takeStr = `&take=${loadOptions.take}`;
                        }

                        if(loadOptions.skip) {
                            skeepStr = `&skeep=${loadOptions.skip}`;
                        }

                        if (loadOptions.sort) {
                            let sort = loadOptions.sort[0];
                            sortStr = `&order_field=${sort.selector}&order_desc=${sort.desc}`;
                        }

                        if (loadOptions.filter) {
                            if(loadOptions.filter.columnIndex != undefined) { // Фильтрация только по одному полю
                                switch (loadOptions.filter.columnIndex) {
                                    case 5:
                                        if (Array.isArray(loadOptions.filter[0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter);
                                        } else {
                                            let dateFilter = moment(loadOptions.filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                            filterStr = this.getFilterItem(loadOptions.filter[0], loadOptions.filter[1], dateFilter);
                                        }
                                        break;
                                    default:
                                        if (Array.isArray(loadOptions.filter[0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter);
                                        } else {
                                            filterStr = this.getFilterItem(loadOptions.filter[0], loadOptions.filter[1], loadOptions.filter[2]);
                                        }
                                        break;
                                }

                                filterStr = filterStr ? `&filter={"and":[${filterStr}]}` : '';
                            } else { // Фильтрация по нескольким полям
                                if(loadOptions.filter[0] != 'driverId') {
                                    if(loadOptions.filter[0] != '!') {
                                        filterStr = this.getFilterGroup(loadOptions.filter);
                                    } else {
                                        if (Array.isArray(loadOptions.filter[1][0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter[1], true);
                                        } else {
                                            filterStr = this.getFilterItem(loadOptions.filter[1][0], '<>', loadOptions.filter[1][2]);
                                        }
                                    }

                                    filterStr = filterStr ? `&filter=${filterStr}` : '';
                                }
                            }
                        }

                        return httpClient.get(`v2/Carrier/Drivers?tokenId=${tokenId}${takeStr}${skeepStr}${sortStr}${filterStr}`)
                            .then((invoices) => {
                                return {
                                    key: 'driverId',
                                    data: invoices.data.items,
                                    totalCount: invoices.data.totals,
                                    summary: invoices.data.totals,
                                    groupCount: invoices.data.count,
                                    userData: invoices.data,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                });
            },
            getFilterOp(op) {
                switch (op) {
                    case '>':
                        return 'gt';
                    case '>=':
                        return 'gte';
                    case '<':
                        return 'lt';
                    case '=<':
                    case '<=':
                        return 'lte';
                    case '=':
                        return 'eq';
                    case '<>':
                        return 'neq';
                    case 'contains':
                        return 'con';
                    case 'notcontains':
                        return 'ncon';
                    case 'startswith':
                        return 'sw';
                    case 'endswith':
                        return 'ew';
                }
            },
            getFilterItem(field_name, op, value) {
                return `{"${field_name}":{"op":"${this.getFilterOp(op)}","value":"${value}"}}`;
            },
            getFilterGroup(group_filters, isNo = false) {
                let condition = '';
                let filter_items = '';

                group_filters.forEach(function (filter) {
                    if (Array.isArray(filter)) {
                        if(Array.isArray(filter[0])) {
                            filter_items += this.getFilterGroup(filter) + ',';
                        } else {
                            if(filter[0] == '!') {
                                if(Array.isArray(filter[1][0])) {
                                    filter_items += this.getFilterGroup(filter[1], true) + ',';
                                } else {
                                    filter_items += this.getFilterItem(filter[1][0], '<>', filter[1][2]) + ',';
                                }
                            } else {
                                switch (group_filters.columnIndex) {
                                    case 5:
                                        var dateFilter1 = moment(filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                        filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), dateFilter1) + ',';
                                        break;
                                    default:
                                        switch (filter.columnIndex) {
                                            case 5:
                                                var dateFilter2 = moment(filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                                filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), dateFilter2) + ',';
                                                break;
                                            default:
                                                filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), filter[2]) + ',';
                                                break;
                                        }
                                        break;
                                }
                            }
                        }
                    } else {
                        condition = filter;
                    }
                }, this);

                filter_items = filter_items.replace(/,$/, "");

                return `{"${condition}":[${filter_items}]}`;
            },
            changeColumns(columns) {
                if(columns.length > 0) {
                    //columns[0].width = '140px';
                    columns[0].visible = true;
                    columns[1].visible = true;
                    columns[2].visible = true;
                    columns[3].visible = true;
                    columns[4].visible = true;
                    columns[5].visible = true;
                    columns[6].visible = true;

                    switch (this.$mq) {
                        case 'sm':
                            columns[0].visible = true;
                            columns[1].visible = true;
                            columns[2].visible = true;
                            columns[3].visible = false;
                            columns[4].visible = false;
                            columns[5].visible = false;
                            columns[6].visible = true;
                            break;
                        case 'md':
                        case 'lg':
                            columns[0].visible = true;
                            columns[1].visible = true;
                            columns[2].visible = true;
                            columns[3].visible = false;
                            columns[4].visible = false;
                            columns[5].visible = false;
                            columns[6].visible = true;
                            break;
                    }
                }

                return columns;
            },
            customizeColumnsDrivers(columns) { //columns
                this.changeColumns(columns);
            },
            onRowClick(e) {
                if(e.rowType == "data" && !e.event.originalEvent.currentTarget.className.includes('dx-button')) {
                    if(this.selectedRowKey != e.key) {
                        if(this.selectedRowKey != "") {
                            this.dataGrid.collapseRow(this.selectedRowKey);
                            this.selectedRowKey = "";
                        }

                        this.selectedRowKey = e.key;

                        this.dataGrid.expandRow(this.selectedRowKey);
                    } else {
                        this.dataGrid.collapseRow(this.selectedRowKey);

                        this.selectedRowKey = "";
                    }
                }
            },
            addNewDriver() { // e
                this.$router.push({name: 'CarrierDriverAdd'});
            },
            resizeEventHandler() { // e
                this.dataGrid.option('columns', this.changeColumns(this.dataGrid.option().columns));
                this.dataGrid.repaint();
            },
            getSexName(sex) {
                switch (sex) {
                    case 'Unknown':
                        return 'Не указан';
                    case 'Male':
                        return 'Мужской';
                    case 'Famale':
                        return 'Женский';
                }
            },
            delDriver(driverId) {
                let isConfirm = confirm("Вы действительно хотите удалить этого водителя?");
                if(isConfirm) {
                    this.$store.dispatch('loading', true).then(() => {
                        return httpClient
                            .delete(`v2/Carrier/Drivers/${driverId}?tokenId=${this.Agent.tokenId}`)
                            .then(() => {
                                this.getDrivers();
                            });
                    });
                }
            },
            editDriver(driverId) {
                this.$router.push({name: 'CarrierDriverEdit', params: {driverId: driverId}});
            }
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ]),
            dataGrid: function() {
                return this.$refs[dataGridRefKey].instance;
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .tooltip-lable {
        display: block;
        position: absolute;
        padding-top: 5px;
        margin-left: 20px;
    }
</style>
